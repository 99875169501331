body.home {
	// background: url(../img/common/bg_bodyTop_home.png) left top repeat-x;
}

.container.header {
	width: 1000px;
	position: absolute;
	top: 753px;
	left: 50%;
	margin-left: -500px;
	height: 135px;
	background: url(../img/common/globalNavi_home2.png) 0 0 no-repeat;
	z-index: 15000;
	h1.site-id {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 240px;
		height: 135px;
		line-height: 135px;
		// border: 1px solid #fff;
		a {
			display: block;
			text-indent: -9999px;
			cursor: pointer;
		}
	}
	ul.globalNavi {
		position: absolute;
		width: 755px;
		top: 28px;
		left: 245px;
		li {
			float: left;
			display: inline;
			zoom: 1;
			position: absolute;
			height: 52px;
			line-height: 52px;
			a {
				display: block;
				text-indent: -9999px;
				cursor: pointer;
			}
		}
	}
}


#gn1 { width: 194px; left: 0; }
#gn2 { width: 224px; left: 194px; }
#gn3 { width: 257px; left: 418px; }
#gn4 { width: 80px; left: 675px; }
#gn5 { top: 52px; width: 280px; left: 0; }
#gn6 { top: 52px; width: 130px; left: 280px; }
#gn7 { top: 52px; width: 77px; left: 410px; }
#gn8 { top: 52px; width: 142px; left: 487px; }
#gn9 { top: 52px; width: 126px; left: 629px; }

/* For Changing Hover Image
------------------------------ */
#gn1 a:hover { width: 194px; left: 0; background: url(../img/common/globalNavi_home2y.png) -245px -28px no-repeat;}
#gn2 a:hover { width: 224px; left: 194px; background: url(../img/common/globalNavi_home2y.png) -439px -28px no-repeat;}
#gn3 a:hover { width: 257px; left: 418px; background: url(../img/common/globalNavi_home2y.png) -663px -28px no-repeat;}
#gn4 a:hover { width: 80px; left: 675px; background: url(../img/common/globalNavi_home2y.png) -920px -28px no-repeat;}
#gn5 a:hover { top: 52px; width: 280px; left: 0; background: url(../img/common/globalNavi_home2y.png) -245px -80px no-repeat;}
#gn6 a:hover { top: 52px; width: 130px; left: 280px; background: url(../img/common/globalNavi_home2y.png) -525px -80px no-repeat;}
#gn7 a:hover { top: 52px; width: 77px; left: 410px; background: url(../img/common/globalNavi_home2y.png) -655px -80px no-repeat;}
#gn8 a:hover { top: 52px; width: 142px; left: 487px; background: url(../img/common/globalNavi_home2y.png) -732px -80px no-repeat;}
#gn9 a:hover { top: 52px; width: 126px; left: 629px; background: url(../img/common/globalNavi_home2y.png) -874px -80px no-repeat;}


/* BXSLIDER
------------------------------ */
.bg-wrapper {
	width: 100%;
	position: relative;
	min-height: 888px;
	height: auto !important;
	height: 888px;
	z-index: 10000; // .slide is 1st layer exc. body/html layer
	background: url(../img/home/bg_bodyTop_home888.png) 0 0 repeat-x;
}



.slide {
	width: 100%;
	min-height: 888px;
	height: auto !important;
	height: 888px;
	position: relative;
	z-index: 0; // .slide is 1st layer exc. body/html layer
	&.sl01 {
		background: url(../img/home/slideImage11.jpg) top center no-repeat;
		.slide-inner {
			// background: url(../img/home/slideImage11.jpg) top center no-repeat;
		}
	}
	&.sl02 {
		background: url(../img/home/slideImage12.jpg) top center no-repeat;
		.slide-inner {
			// background: url(../img/home/slideImage12.jpg) top center no-repeat;
		}
	}
	&.sl03 {
		background: url(../img/home/slideImage13.jpg) top center no-repeat;
		.slide-inner {
			// background: url(../img/home/slideImage13.jpg) top center no-repeat;
		}
	}
	&.sl04 {
		background: url(../img/home/slideImage14.jpg) top center no-repeat;
		.slide-inner {
			// background: url(../img/home/slideImage14.jpg) top center no-repeat;
		}
	}
	&.sl05 {
		background: url(../img/home/slideImage15.jpg) top center no-repeat;
		.slide-inner {
			// background: url(../img/home/slideImage15.jpg) top center no-repeat;
		}
	}
	&.sl06 {
		background: url(../img/home/slideImage16.jpg) top center no-repeat;
		.slide-inner {
			// background: url(../img/home/slideImage16.jpg) top center no-repeat;
		}
	}
	.slide-inner {
		min-height: 688px;
		height: auto !important;
		height: 688px;
		width: 1000px;
		margin: 0 auto;
	}
	.slide-inner a {
		height: 688px;
		width: 100%;
		position: absolute;
		left: 0;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}
}

.section {
	padding-bottom: 60px;
}

.homeNews {
	height: 64px;
	position: relative;
	a {
		color: #000;
	&:hover {
		text-decoration: underline;
	}
	}
	h3 {
		position: absolute;
		top: 0;
		left: 0;
	}
	h4 {
		font-size: 13px;
		line-height: 1.2;
		font-weight: bold;
	}
	p {
		font-size: 12px;
	}
	p.postDate {
		color: #ccc;
		position: absolute;
		top: 15px;
		left: 167px;
	}
	.detail-inner {
		width: 600px;
		position: absolute;
		top: 17px;
		left: 270px;
		min-height: 40px;
		height: auto !important;
		height: 40px;
		overflow-y: auto;
	}
	p.entryList {
		position: absolute;
		top: 12px;
		right: 0;
	}
}

.home-unit {
	margin-bottom: 1px;
}

/* SLIDE NAVI MODULES
------------------------------ */
.slideNavi {
	width: 963px;
	background-color: #333;
	color: #fff;
	padding: 18px 12px 0 25px;
	// opacity: 0.9;
	// filter: alpha(opacity=95);
	h4,
	p {
		line-height: 1.6;
		font-size: 13px;
		padding-right: 25px;
	}
	h4 {
		font-weight: bold;
	}
	ul {
		padding-top: 12px;
		li {
			font-size: 12px;
			margin-bottom: 22px;
		&.sn1,
		&.sn2,
		&.sn4,
		&.sn5,
		&.sn7,
		&.sn8 {
			width: 350px;
		}
		&.sn3,
		&.sn6,
		&.sn9 {

		}
			float: left;
			display: inline;
			zoom: 1;
			a {
				color: #fc0;
				padding-left: 14px;
				background: url(../img/common/bullet_sn_gt.gif) 0 0 no-repeat;
			&:hover {
				text-decoration: underline;
			}
			}
		}
	}
	position: absolute;
	z-index: 20000;
}

.slideButton:hover {
	img {
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
}


.unit#casestudy {
	.left-inner {
		width: 300px;
		float: left;
		display: inline;
		zoom: 1;
		height: 300px;
	}
	.right-inner {
		width: 700px;
		margin-left: 300px;
		height: 300px;
		background: url(../img/home/bg_home_case.jpg) 0 0 no-repeat;
		overflow-y: auto;
		ul {
			li {
				width: 288px;
				float: left;
				display: inline;
				zoom: 1;
				padding: 34px 35px 28px 17px;
				color: #fff;
				a {
					color: #fff;
					text-decoration: underline;
				}
				.postImage {
					margin-bottom: 8px;
					img {
						max-width: 240px;
						height: auto;
					}
				}
				h4.postTitle {
					font-size: 13px;
				}
				p.postDesc {
					font-size: 12px;
					line-height: 1.4;
				}
			}
		}
	}
}

.unit#companyinfo {
	ul {
		li {
			float: left;
			display: inline;
			zoom: 1;
		}
	}
}